/* Hero section styles */
.hero {
  font-family: 'Zen Dots', sans-serif;
  color: white;
  padding: 8rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-heading {
  margin-bottom: 3rem;
}

.hero-heading img {
  max-width: 65%;
  height: auto;
  margin-bottom: 1rem;
  transition: transform 0.5s ease, filter 0.5s ease;
}

.hero-heading img:hover,
.hero-heading h2:hover,
.event-date:hover,
.event-venue:hover {
  transition: transform 0.9s ease, filter 0.9s ease;
  transform: scale(1.05);
  filter: brightness(1.0);
}

.hero-heading h2 {
  font-size: 2.5rem;
  color: #D5E1FF;
}

.apply-button-container {
  background-color: #fff;
  height: 44px;
  width: 312px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.apply-button {
  z-index: 10;
}

.apply-button-container:hover {
  box-shadow: 0px 0px 50px 0px rgba(119, 34, 245, 1);
}

.hero-subheading {
  color: white;
  margin-left: 8.4rem;
  text-align: center;
  margin-bottom: 4rem;
}

.event-date {
  margin-top: 1rem;
  font-size: 2rem;
  color: #aabffd;
  padding-right: 7.5rem;
}

.event-venue {
  font-family: "Josefin Sans", sans-serif;
  font-size: 2rem;
  color: rgb(194, 194, 194);
  padding-right: 7.5rem;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .hero-heading {
    margin-left: 10px;
    text-align: center;
  }

  .hero-heading img {
    max-width: 80%;
    height: auto;
  }

  .hero-subheading {
    margin-left: 0;
  }

  .apply-button-container {
    width: 80%;
    margin: 1rem auto;
  }
}

@media screen and (max-width: 576px) {
  .hero-heading img {
    max-width: 90%;
    height: auto;
    margin-left: 0;
    text-align: center;
  }

  .hero-heading h2 {
    font-size: 1.2rem;
    margin-left: 0;
    text-align: center;
    margin-right: 0;
  }

  .event-date {
    font-size: 2rem;
    margin-right: -3rem;
    margin-left: 3rem;
  }

  .event-venue {
    font-size: 1.5rem;
    margin-right: -4rem;
    margin-left: 2rem;
  }

  .apply-button-container {
    width: 90%;
  }
}

/* Winner Cards Container */
.winner-cards {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
  margin-top: 3rem;
  flex-wrap: wrap;
}

/* Individual Winner Card */
.winner-card {
  width: 12rem;
  height: 14rem;
  background-color: black;
  border: 2px solid white;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Work Sans', sans-serif;
}

.winner-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.5);
}

/* Trophy Icon Styling */
.trophy-icon {
  font-size: 2rem;
  color: #FFD700;
}

/* Card Content */
.card-content {
  text-align: center;
}

.winner-prize {
  font-size: 1rem;
}

.winner-team-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.winner-project-name {
  font-size: 1rem;
  font-weight: normal;
}

/* Dropdown Styles */
.team-scores-button-holder {
  position: relative;
  margin-top: 2rem;
}

.team-scores-button {
  background-color: #6c63ff;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: 'Work Sans', sans-serif;
}

.team-scores-button:hover {
  background-color: #5a52e1;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Work Sans', sans-serif;
}

.dropdown li:hover {
  background-color: #444;
}
