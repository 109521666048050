.supporters-section {
  font-family: "Work Sans", sans-serif;
  position: relative;
}

.support-content {
  margin-bottom: 2rem;
  color: white;
  
}

.support-heading {
  display: inline-block;
  z-index: 10;
  position: relative;
  margin-top: -1rem;
  padding-left: 3rem;
  text-align: left;
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: transparent;
  --s: 0.08em;
  --c: #fff;
  background-image: linear-gradient(#d5e1ff, #4156f3);
  -webkit-background-clip: text;
  background-clip: text;
  transition: color 0.7s ease;
}

.support-heading::before {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: 0;
  width: 0;
  height: var(--s);
  background-color: var(--c);
  transition: width 0.5s ease-in-out;
}

.support-heading:hover {
  color: transparent;
  background-image: linear-gradient(#dfe1ff, #4156f3);
  font-variation-settings: "wght" 700;
}

.support-heading:hover::before {
  width: 100%;
}

.support-tier {
  text-align: center;
  margin-bottom: 4rem;
}

.support-tier h2 {
  background-image: linear-gradient(#d5e1ff, #4156f3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 2.5rem;
}

.support-tier h3 {
  background-image: linear-gradient(#d5e1ff, #4156f3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 2rem;
}

.plat-supporters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8rem;
}

.plat-supporters img {
  max-width: 20rem;
  height: auto;
}

.devfolio-logo {
  padding-top: 2rem;
}

.interview-cake img {
  margin-top: -3rem;
}

.gold-supporters {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 5rem;
  justify-content: center;
  max-width: 50rem;
  margin: auto;
}

.gold-supporters img {
  max-width: 15rem;
  height: auto;
}

/* Center the last item if it's the only one in its row */
.gold-supporters > *:last-child:nth-child(odd) {
  grid-column: span 2; /* Span across both columns */
  justify-self: center; /* Center the item */
}


.silver-supporters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem;
  max-width: 50rem;
  margin: auto;
}

.silver-supporters img {
  flex: 1 0 auto;
  max-width: 13rem;
}

.liquidmind img {
  max-width: 24rem;
}

.support-block {
  position: relative;
}

.certificate-partner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.certificate-partner img {
  max-width:8rem;
  height: auto;
}

.gmc-text {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 8px;
  text-align: center;
}

.saily img {
  max-width: 10rem;
  margin-top: -1rem;
}

.incogni img{
  max-width: 10rem;
}

.interview-cake img {
  max-width: 17rem;
  margin-top: 0.5rem;
}

.kwantumg img {
  margin-top: 2rem;
  max-width: 23rem;
}

.womanium img {
  margin-top: 3rem;
  max-width: 25rem;
}

.domain-partner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.domain-partner img{
  max-width:9rem;
  height: auto;
}

.snack-partner {
  display: flex;
  flex-wrap: wrap;
  gap: 10rem;
  justify-content: center;
}

.snack-partner img {
  max-width: 12rem;
  height: auto;
}

.background-glow-element {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  pointer-events: none;
}

.glow-top-left {
  background-image: url(../img/sponsors/glow-top-left.svg);
  left: -20rem;
  top: -10rem;
  height: 40rem;
  width: 40rem;
  opacity: 0.05;
}

.glow-top-right {
  background-image: url(../img/sponsors/glow-top-right.svg);
  right: -45rem;
  top: -20rem;
  height: 75rem;
  width: 100%;
  opacity: 0.05;
  z-index: -1;
}

.glow-right {
  background-image: url(../img/sponsors/glow-right.svg);
  right: -45rem;
  top: 25rem;
  height: 35rem;
  width: 100%;
  opacity: 0.1;
  z-index: -1;
}

.bg-loop-design {
  background-image: url(../img/sponsors/bg-loop-design.svg);
  left: -20rem;
  top: -3rem;
  height: 75rem;
  width: 100%;
  opacity: 0.1;
  z-index: -1;
}

.right-looper {
  background-image: url(../img/sponsors/right-loop.svg);
  right: -35rem;
  top: -10rem;
  height: 75rem;
  width: 100%;
  opacity: 0.12;
  transform: rotate(20deg);
  z-index: -1;
}

/* Initial state: hidden and slightly down */
.fade-in-element {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

/* Visible state: fully visible and aligned */
.fade-in-visible {
  opacity: 1;
  transform: translateY(0);
}

#box {
  display: flex;
  flex-direction: column;


  padding-top: 1rem;
  width: 250px;
  color: white;
  padding-left: 1rem;
  padding-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
}
#box a {
  background-color: #4156f3;
  color: white;
  cursor: pointer;
}
.gradient-border {
  --borderWidth: 3px;
  background: #09080c;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #d5e1ff,
    #aabffd,
    #8896f1,
    #6a69de,
    #4156f3,
    #3a4ff4
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.support-more-holder {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Media Queries for Responsive Design */

@media (max-width: 1200px) {
  .supporters-content-wrapper {
    padding: 3rem;
  }

  .support-heading {
    font-size: 3.5rem;
    padding-left: 2rem;
  }

  .support-tier {
    margin-bottom: 4rem;
  }

  .plat-supporters img {
    max-width: 17rem;
  }

  .gold-supporters {
    gap: 4rem;
  }

  .gold-supporters img {
    max-width: 12rem;
  }

  .kwantumg img {
    max-width: 18rem;
  }

  .womanium img {
    max-width: 18rem;
    margin-top: 3.5rem;
  }

  .xDay img {
    margin-top: 1rem;
  }

  .silver-supporters img {
    max-width: 15rem;
  }

  .certificate-partner img,
  .domain-partner img {
    max-width: 7rem;
  }

  .snack-partner {
    gap: 3rem;
  }

  .snack-partner img {
    max-width: 9rem;
  }

  #box {
    padding-top: 0.8rem;
    width: 200px;
    padding-left: 0.8rem;
    padding-bottom: 1.8rem;
    font-size: 1.2rem;
  }

  .incogni img,
  .saily img {
    max-width: 8rem;
  }

}

@media (max-width: 768px) {
  .support-heading {
    font-size: 3rem;
  }

  .support-tier {
    margin-bottom: 3rem;
  }

  .plat-supporters img,
  .gold-supporters img {
    max-width: 10rem;
  }

  .gold-supporters {
    gap: 3rem;
  }

  .kwantumg img {
    max-width: 14rem;
  }

  .silver-supporters img {
    max-width: 10rem;
  }

  .certificate-partner img,
  .domain-partner img {
    max-width: 6rem;
  }

  #box {
    padding-top: 0.6rem;
    width: 150px;
    padding-left: 0.6rem;
    padding-bottom: 1.4rem;
    font-size: 1rem;
  }

  .incogni img,
  .saily img {
    max-width: 8rem;
  }

  .snack-partner img {
    max-width: 7rem;
  }

}

@media (max-width: 576px) {
  .support-heading {
    margin-top: 0.5rem;
    font-size: 3.5rem;
    text-align: left;
    padding-left: 3rem;
  }

  .support-tier {
    margin-bottom: 2rem;
  }

  .support-tier h2 {
    font-size: 1.9rem;
    padding-bottom: 1rem;
  }

  .plat-supporters {
    gap: 3rem;
  }

  .plat-supporters img {
    max-width: 11rem;
  }

  .devfolio-logo {
    margin-top: -2rem;
  }

  .gold-supporters img {
    max-width: 8rem;
  }

  .gold-supporters {
    gap: 2rem;
    z-index: 1;
  }

  .kwantumg img {
    margin-top: auto;
    max-width: 15rem;
  }

  .silver-supporters {
    gap: 2rem;
  }

  .silver-supporters img {
    max-width: 6rem;
  }

  .interview-cake img {
    margin-top: 0.5rem;
  }

  .certificate-partner img,
  .domain-partner img {
    max-width: 5rem;
  }

  .domain-partner {
    flex-direction: column;
    gap: 0.5rem;
  }

  #box {
    padding-top: 0.5rem;
    width: 140px;
    padding-left: 0.5rem;
    padding-bottom: 1.2rem;
    font-size: 0.9rem;
  }

  .incogni img,
  .saily img {
    max-width: 5rem;
  }

  .xDay {
    margin-top: -2rem;
  }

  .xDay img {
    max-width: 8rem;
  }

  .womanium img {
    max-width: 10rem;
    margin-top: 1rem;
    margin-right: 3rem;
  }

}
