.about-us-section {
  font-family: "Work Sans", sans-serif;
}

.about-us-main {
  margin-top: 3rem;
  margin-bottom: 8rem;
  position: relative;
  z-index: 100;
}

.about-us-content-wrapper {
  padding-left: 5rem;
  padding-right: 30rem;
}

.about-us-content {
  margin-bottom: 4rem;
  color: white;
}

.about-us-main-heading {
  display: inline-block;
  text-align: left;
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background-image: linear-gradient(#D5E1FF, #4156F3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  --s: 0.08em;
  --c: #fff;
  padding-bottom: var(--s);
  position: relative;
  z-index: 10;
}

.about-us-main-heading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: var(--s);
  background-color: var(--c);
  transition: width 0.5s ease-in-out;
}

.about-us-main-heading:hover {
  color: transparent;
  background-image: linear-gradient(#D5E1FF, #4156F3);
  font-variation-settings: "wght" 700;
}

.about-us-main-heading:hover::before {
  width: 100%;
}

.about-us-desc {
  font-family: "Work Sans", sans-serif;
  font-size: 1.5rem;
  position: relative;
  font-weight: 400;
  opacity: .9;
  z-index: 100;
}

.about-us-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-bottom: 4rem;
  position: relative;
  z-index: 10;
  opacity: .85;
}

.stat-item {
  text-align: left;
  font-family: "Work Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
}

.stat-number {
  font-size: 4rem;
  font-weight: 650;
  line-height: 3.5rem;
  letter-spacing: 1.2px;
}

.plus {
  color: #8896f1;
}

.left-about-us-design {
  background-image: url(../img/aboutus/left-about-us.svg);
  width: 60rem;
  height: 50rem;
  left: -20rem;
  bottom: -10rem;
  z-index: 2;
  pointer-events: none;
}

.right-about-us-design {
  position: absolute;
  width: 20rem;
  height: 20rem;
  z-index: 2;
  top: -1rem;
  right: 7rem;
  z-index: 500;
  pointer-events: none;
}

.bg-design {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  pointer-events: none;
}

.main-circle {
  background-image: url(../img/aboutus/main-circle.svg);
  width: 25rem;
  height: 25rem;
  left: 14rem;
  top: 3.5rem;
  z-index: 15;
  animation: rotateLoop 50s linear infinite;
  pointer-events: none;
}


.bg-looper {
  background-image: url(../img/aboutus/bg-looper.svg);
  width: 65rem;
  height: 65rem;
  top: -15rem;
  left: -8rem;
  opacity: .7;
  animation: rotateLoopcounter 150s linear infinite;
  pointer-events: none;
}

.bg-circle {
  background-image: url(../img/aboutus/bg-circle.svg);
  width: 30rem;
  height: 30rem;
  left: 11rem;
  top: 1rem;
  pointer-events: none;
}

.bg-glow {
  background-image: url(../img/aboutus/bgglow.svg);
  width: 100rem;
  height: 100rem;
  bottom: -50rem;
  left: -26rem;
  opacity: .5;
  animation: pulsate 30s infinite;
  transform: rotate(273deg);
  pointer-events: none;
}

@keyframes rotateLoopcounter {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

@keyframes rotateLoop {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.5;
  }
}

/* Add fade-in animation */
.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in-section.visible {
  opacity: 1;
  transform: translateY(0);
}




/* Media Queries */
@media screen and (max-width: 1200px) {
  .about-us-content-wrapper {
    padding-right: 10rem;
  }
}

@media screen and (max-width: 992px) {
  .about-us-content-wrapper {
    padding-right: 15rem;
  }

  .about-us-main-heading {
    font-size: 3.5rem;
  }

  .about-us-desc {
    font-size: 1.2rem;
  }

  .stat-item {
    font-size: 1.2rem;
  }

  .stat-number {
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .about-us-content-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .about-us-main-heading {
    font-size: 3rem;
    text-align: center;
  }

  .about-us-desc,
  .stat-item,
  .stat-number {
    font-size: 1rem;
    text-align: center;
  }

  .left-about-us-design {
    width: 40rem;
    height: 35rem;
    left: calc(50% - 20rem);
    padding-bottom: 15rem;
    display: none;
  }

  .main-circle,
  .bg-circle {
    display: none;
  }

  .bg-looper {
    width: 50rem;
    height: 50rem;
    top: -10rem;
    left: calc(50% - 18rem);
    z-index: -1;
    opacity: 0.3;
  }

  .right-about-us-design {
    position: static;
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }

  .bg-glow {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .about-us-main-heading {
    font-size: 2.5rem;
    text-align: center;
    padding-left: 3rem;
  }

  .about-us-desc,
  .stat-item,
  .stat-number {
    font-size: 0.9rem;
  }

  .left-about-us-design {
    width: 30rem;
    height: 25rem;
    left: calc(50% - 15rem);
    bottom: -20rem;
  }

  .bg-looper {
    width: 40rem;
    height: 40rem;
    top: -8rem;
    left: calc(50% - 12rem);
  }

  .bg-glow {
    display: none;
  }

  .about-us-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: -5rem;
  }

  .stat-item {
    flex: 0 0 25%;
    margin: 0.8rem;
    text-align: center;
  }

  .right-about-us-design {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;            
    right: 8rem;        
    z-index: -1;         
  }
  
  
}