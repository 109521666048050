
body {
  /*font-family: 'Zen Dots', sans-serif;*/
  font-family: "Work Sans", sans-serif;
  background-color: black;
  color: #8896F1;
  overflow-x: hidden;
  width: 100vw;
}

.App {
  padding: 2rem;
  animation: fadeIn 1s ease-in-out;
  margin: 0rem;
  padding: 0rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}