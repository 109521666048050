.judges-header {
    display: inline-block;
    text-align: left;
    position: relative;
    font-size: 4.5rem;
    margin: 2rem 1rem 2rem 4rem;
    font-weight: 600;
    background-image: linear-gradient(#D5E1FF, #4156F3);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.judge-container {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allow cards to wrap to new lines */
}

.judge-cards-wrapper {
    display: flex;
    flex-wrap: wrap; /* Ensure cards wrap to the next line if needed */
    justify-content: center;
    align-items: center;
    gap: 1.75rem;
    width: 100%;
}

.judge-card {
    width: 11rem;
    height: 20rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.625rem;
    padding: 1.25rem;
    text-align: center;
    background-color: rgb(7, 2, 12);
    font-family: 'Work Sans', sans-serif;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    transition: box-shadow 0.3s ease-in-out;
}

.judge-card:hover {
    box-shadow: 0 0 3.125rem rgba(207, 177, 244, 0.5);
}

.judge-card-photo {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0.9375rem;
}

.judge-card-name {
    font-size: 1.5rem;
    margin: 0.625rem 0;
    font-weight: 500;
}

.judge-card-company {
    font-size: 1.3rem;
    margin: 0.3125rem 0;
    font-weight: bold;
}

.judge-card-designation {
    font-size: 1.0rem;
    margin: 0.3125rem 0;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .judge-card {
        width: 9rem;
        height: 18rem;
        padding: 1rem;
    }

    .judge-card-photo {
        width: 7rem;
        height: 7rem;
    }

    .judge-card-name {
        font-size: 1.2rem;
    }

    .judge-card-company {
        font-size: 1rem;
    }

    .judge-card-designation {
        font-size: 0.875rem;
    }
}

@media (max-width: 480px) {
    .judges-header {
        font-size: 3rem;
        margin: 1rem;
    }

    .judge-card {
        width: 7.5rem;
        height: 16rem;
        padding: 0.75rem;
    }

    .judge-card-photo {
        width: 6rem;
        height: 6rem;
    }

    .judge-card-name {
        font-size: 1rem;
    }

    .judge-card-company {
        font-size: 0.875rem;
    }

    .judge-card-designation {
        font-size: 0.75rem;
    }

    .judge-cards-wrapper {
        gap: 0.75rem;
    }
}
