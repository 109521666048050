/* Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 60px;
    background-color: transparent;
    transition: all 0.5s ease;
    border-radius: 30px;
    z-index: 1000;
  }
  
  /* Oval shape with black gradient on scroll */
  .navbar.scrolled {
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 1));
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  }
  
  /* Logo */
  .qbit-logo {
    background-image: url(../img/home/logonew.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 50px;
    width: 150px;
  }
  
  /* Desktop Navbar Links */
  .navbar-links {
    display: flex;
    gap: 20px;
  }
  
  .navbar-links button {
    background: none;
    border: none;
    color: #d5e1ff;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.04rem;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    transition: color 0.3s ease;
  }
  
  .navbar-links button:hover {
    color: #93caff;
    text-decoration: underline;
  }
  
  /* Hamburger Button (hidden on desktop) */
  .hamburger-btn {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #d5e1ff;
    margin: 5px 0;
    transition: transform 0.4s ease-in-out;
  }
  
  /* Show hamburger button on smaller screens */
  @media (max-width: 768px) {
    .hamburger-btn {
      display: block;
    }
  
    .navbar-links {
      display: none; /* Hide navbar links when hamburger is clicked */
    }
  }
  
  /* Popup Menu */
  .popup-menu {
    position: absolute;
    top: 70px;
    right: 10px;
    width: 180px;
    background: linear-gradient(135deg, #3a3a3a, #1e1e1e, #0e0e0e);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    z-index: 1500; /* Higher z-index to appear over all elements */
    animation: slideDown 0.3s ease-in-out;
  }
  
  .popup-links {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 10px;
  }
  
  .popup-links button {
    background: none;
    border: none;
    color: #d5e1ff;
    font-size: 1.1rem;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    padding: 10px 0;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .popup-links button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #93caff;
  }
  
  /* Slide Down Animation */
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  