/*
*{
   border: 2px solid red;
  border-radius: 5px;
}
*/
body {
  margin: 0;
  padding: 0;
  font-family: 'Zen Dots', sans-serif;

  color: yellow;
}

code {
  font-family: 'Zen Dots', sans-serif;
}

a {
  color: #0066cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.App {
  padding: 2rem;
}

.footer {
  text-align: center;
  padding: 2rem;
  background-color: #f2f2f2;
  margin-top: 2rem;
}

.footer p {
  margin-top: 0;
  margin-bottom: 0;
}

/* Add global styles for other components here */