.contact {
  font-family: "Work Sans", sans-serif;
}

.contact-heading {
  display: inline-block;
  text-align: left;
  padding-left: 3rem;
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background-image: linear-gradient(#D5E1FF, #4156F3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  --s: 0.08em;
  --c: #fff;
  padding-bottom: var(--s);
  position: relative;
  z-index: 1;
}

.contact-heading::before {
  content: "";
  position: absolute;
  left: 2rem;
  bottom: 0;
  width: 0;
  height: var(--s);
  background-color: var(--c);
  transition: width 0.5s ease-in-out;
}

.contact-heading:hover {
  color: transparent;
  background-image: linear-gradient(#D5E1FF, #4156F3);
  font-variation-settings: "wght" 700;
}

.contact-heading:hover::before {
  width: 100%;
}

.map-section {
  display: flex;
  justify-content: center; /* Centers the iframe */
  margin-bottom: 1rem;
}

.map-section iframe {
  width: 100%;
  max-width: 600px;
  height: 450px;
  border: 0;
}

.travel-button-holder {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.travel-button-holder a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  display: inline-block;
  position: relative;
  text-align: center;
  background-color: #6a69de;
  border-radius: 3.125rem;
  line-height: 2em;
  padding: 0 3rem;
  box-shadow: 0 0 0 0 transparent;
  transition: all 0.2s ease-in;
}

.travel-button-holder a:hover {
  color: white;
  box-shadow: 0 0 30px rgba(170, 119, 251, 0.5);
  background-color: #6a69de;
  transition: all 0.2s ease-out;
}

.travel-button-holder a:hover:before {
  animation: shine 0.5s linear;
}

.travel-button-holder a:active {
  box-shadow: 0 0 0 0 transparent;
  transition: box-shadow 0.2s ease-in;
}

.travel-button-holder a:before {
  content: "";
  display: block;
  width: 0;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0;
  opacity: 0;
  background: white;
  box-shadow: 0 0 15px 3px white;
  transform: skewX(-20deg);
}

@keyframes shine {
  from {
    opacity: 0;
    left: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    left: 100%;
  }
}


.contact-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  gap: 2rem;
}

.contact-buttons a {
  margin: 1rem;
}

.contact-buttons img {
  width: 4rem;
  height: 4rem;
  border-radius: 80%;
}

.contact-buttons svg {
  width: 40px;
  height: 24px;
  fill: white;
}

.contact-buttons svg:hover {
  fill: #ff9900;
}

.fade-in-element {
  opacity: 0;
  transform: translateY(20px); /* Adjust as needed */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-visible {
  opacity: 1;
  transform: translateY(0);
}



@media (max-width: 992px) {
  .contact-heading {
    font-size: 3rem;
  }

  .map-section iframe {
    width: 100%;
    max-width: 500px;
    height: 400px;
  }

  .contact-buttons img {
    width: 40px;
    height: 40px;
  }

  .contact-buttons svg {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 768px) {
  .contact-heading {
    padding-left: 0;
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center;
  }

  .map-section iframe {
    width: 100%;
    max-width: 400px;
    height: 350px;
  }

  .contact-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-buttons a {
    margin: 0 10px;
  }

  .contact-buttons img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  .contact-buttons svg {
    width: 24px;
    height: 24px;
    fill: white;
  }

  .contact-buttons:hover {
    grid-auto-flow: row;
  }

  .travel-button-holder a {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .contact-heading {
    font-size: 2rem;
    text-align: center;
    padding-left: 1rem;
  }

  .map-section iframe {
    width: 100%;
    max-width: 300px;
    height: 250px;
  }

  .contact-buttons img {
    width: 40px;
    height: 40px;
  }

  .contact-buttons svg {
    width: 20px;
    height: 20px;
  }

  .travel-button-holder a {
    font-size: 1.2rem;
  }
}
