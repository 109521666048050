.timeline-container {
  font-family: "Work Sans", sans-serif;
  padding: 2rem;
  position: relative;
}

.timeline-heading,
.timeline-content,
.left-timeline-design,
.right-timeline-design {
  opacity: 0; /* Start invisible */
  transform: translateY(1.25rem); /* Start slightly below */
  transition: opacity 0.7s ease, transform 0.7s ease; /* Transition effects */
}

.timeline-heading.fade-in,
.timeline-content.fade-in,
.left-timeline-design.fade-in,
.right-timeline-design.fade-in {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Move to original position */
}

.timeline-heading {
  display: inline-block;
  color: transparent;
  position: relative;
  padding-left: 2rem;
  font-size: 4.5rem;
  margin-bottom: 4rem;
  font-weight: 600;
  --s: 0.08em;
  --c: #fff;
  padding-bottom: var(--s);
  background-image: linear-gradient(#d5e1ff, #4156f3);
  -webkit-background-clip: text;
  background-clip: text;
  transition: color 0.7s ease, padding-bottom 0.7s ease;
  z-index: 1;
}

.timeline-heading::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: var(--s);
  background-color: var(--c);
  transition: width 0.5s ease-in-out;
}

.timeline-heading:hover {
  color: transparent;
  background-image: linear-gradient(#dfe1ff, #4156f3);
  font-variation-settings: "wght" 700;
}

.timeline-heading:hover::before {
  width: 100%;
}

.day-buttons {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

.day-buttons button {
  margin: 0 5rem;
  padding: 0.8rem 2rem;
  background: linear-gradient(145deg, #6a69de, #5958d2);
  color: white;
  font-weight: bold;
  font-size: 1rem;
  border: none;
  border-radius: 0.625rem;
  cursor: pointer;
  transition: all 0.4s ease; /* Increase transition duration */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.day-buttons button:hover {
  background: linear-gradient(145deg, #5a5cd3, #4a4ac4);
  transform: scale(1.08); /* Increase zoom effect */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow */
  filter: brightness(1.1); /* Slightly brighten */
}

.day-buttons button.active {
  background: linear-gradient(145deg, #4a4ac4, #3a39b4);
  color: #fff;
  border: 3px solid #ffffff;
  transform: scale(1.2); /* Larger size on activation */
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3),
    0px 0px 15px 5px rgba(74, 74, 196, 0.7); /* Glow effect */
  position: relative;
  overflow: hidden;
}

/* Glowing pulse animation */
.day-buttons button.active::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3),
    transparent 60%
  );
  animation: pulseGlow 2s infinite;
  pointer-events: none;
  border-radius: 50%;
}

@keyframes pulseGlow {
  0% {
    transform: scale(0.9);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}

.timeline {
  background: var(--primary-color);
  margin: 1rem auto;
  width: 100%;
  max-width: 30rem;
  padding-bottom: 2rem;
}

.timeline-text {
  text-align: left;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  background-image: linear-gradient(#d5e1ff, #4156f3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.card {
  position: relative;
  max-width: 30rem;
  font-size: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.card.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.card:nth-child(1) {
  transition-delay: 0.1s;
}
.card:nth-child(2) {
  transition-delay: 0.3s;
}
.card:nth-child(3) {
  transition-delay: 0.5s;
}
.card:nth-child(4) {
  transition-delay: 0.7s;
}
.card:nth-child(5) {
  transition-delay: 0.9s;
}

.card:nth-child(odd) {
  padding: 1.875rem 0 1.875rem 1.875rem;
}

.card:nth-child(even) {
  padding: 1.875rem 1.875rem 1.875rem 0;
}

.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid #4156f3;
}

.card:nth-child(odd)::before {
  left: 0;
  top: -0.28125rem;
  bottom: -0.28125rem;
  border-width: 0.3125rem 0 0.3125rem 0.3125rem;
  border-radius: 3.125rem 0 0 3.125rem;
}

@media only screen and (max-width: 25rem) {
  .card:nth-child(odd)::before {
    top: -0.3125rem;
    bottom: -0.3125rem;
  }
}

.card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: 0;
  border-width: 0.3125rem 0.3125rem 0.3125rem 0;
  border-radius: 0 3.125rem 3.125rem 0;
}

.card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
}

.card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

.info {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  flex-shrink: 1;
  position: relative;
  background: transparent;
  min-height: 10rem;
  overflow: hidden;
  background-image: linear-gradient(90deg, #19171c 5%, #100e12 20%),
    linear-gradient(90deg, hsla(0, 0%, 100%, 0.166), hsl(0, 0%, 100%));
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 2px solid transparent;
  border-radius: 1.75rem;

  transition: opacity 0.7s ease;
}

.title {
  color: #d6daff;
  font-size: 1.9rem;
  position: relative;
  font-weight: bold;
  padding-bottom: 1rem;
}
.event_desc {
  color: white;
  font-size: 1rem;
  /* width: 300px; */
}

.info-holder::before {
  content: "";
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  background: white;
  border-radius: 999px;
  border: 0.1875rem solid #6a69de;
}

.card:nth-child(even) > .info-holder > .info {
  flex-direction: row-reverse;

  background-image: linear-gradient(to left, #19171c 5%, #100e12 20%),
    linear-gradient(to left, hsla(0, 0%, 100%, 0.166), hsl(0, 0%, 100%));
}


.card:nth-child(odd) > .info-holder > .info > .title_and_desc {
  padding: 1.5rem 1rem 1.5rem 1rem;
}
.card:nth-child(even) > .info-holder > .info > .title_and_desc {
  padding: 1.5rem 1.5rem 1rem 1rem;
}

.card:nth-child(odd) > .info-holder::before {
  top: 4rem;
  left: -0.3rem;
}

.card:nth-child(even) > .info-holder::before {
  top: 4rem;
  right: -0.3rem;
}

.title_and_desc {
  display: flex;
  flex-direction: column;

  position: relative;
}

.time-holder {
  background-color: #6a69de;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 100px;
}
.time{
  font-size: 1.8rem;
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
}

.time-to{
  font-size: 1.5rem;
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 2px rgb(255, 255, 255); 
  color: #6a69de; 
  paint-order: stroke fill;
  letter-spacing: 0.2rem;
}

.left-timeline-design,
.right-timeline-design {
  position: absolute;
  top: 11rem;
  width: 30rem;
  height: 100%;
  z-index: -1;
  background-size: contain;
  background-repeat: no-repeat;
}

.left-timeline-design {
  left: -4rem;
  background-image: url("../img/timeline/left.svg");
}

.right-timeline-design {
  right: 0;
  background-image: url("../img/timeline/right.svg");
}

.guide-button-holder {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}

.guide-button-holder a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 1.8rem;
  display: inline-block;
  position: relative;
  text-align: center;
  background-color: #6a69de;
  border-radius: 3.125rem;
  line-height: 2em;
  padding: 0 3rem;
  box-shadow: 0 0 0 0 transparent;
  transition: all 0.2s ease-in;
}

.guide-button-holder a:hover {
  color: white;
  box-shadow: 0 0 30px rgba(170, 119, 251, 0.5);
  background-color: #6a69de;
  transition: all 0.2s ease-out;
}

.guide-button-holder a:hover:before {
  animation: shine 0.5s linear;
}

.guide-button-holder a:active {
  box-shadow: 0 0 0 0 transparent;
  transition: box-shadow 0.2s ease-in;
}

.guide-button-holder a:before {
  content: "";
  display: block;
  width: 0;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0;
  opacity: 0;
  background: white;
  box-shadow: 0 0 15px 3px white;
  transform: skewX(-20deg);
}

@keyframes shine {
  from {
    opacity: 0;
    left: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    left: 100%;
  }
}

@media (max-width: 75rem) {
  .guide-button-holder a {
    font-size: 1.5rem;
  }
}

@media (max-width: 62rem) {
  .right-timeline-design {
    right: -2rem;
  }

  .day-buttons button {
    margin: 0 2rem; /* Reduce margins for smaller screens */
    padding: 0.7rem 1.8rem;
    font-size: 0.95rem;
  }

  .timeline-text {
    font-size: 1.4rem;
    padding: 1rem;
  }
}

@media (max-width: 48rem) {
  .timeline-heading {
    font-size: 3rem;
    margin: 1rem;
  }

  .left-timeline-design,
  .right-timeline-design {
    display: none;
  }
  .guide-button-holder a {
    font-size: 1.3rem;
  }
}

@media (max-width: 36rem) {
  .timeline-heading {
    font-size: 3.5rem;
    margin: 0.5rem;
    text-align: center;
  }

  .timeline-events-image {
    position: relative;
    max-width: 70%;
    padding-right: 13rem;
  }

  .title {

    font-size: 1.5rem;

  }
  .event_desc {

    font-size: 0.8rem;
    /* width: 300px; */
  }
  .time{
    font-size: 1.5rem;

  }

  .guide-button-holder a {
    font-size: 1.2rem;
  }

  .day-buttons {
    flex-direction: column; /* Stack buttons vertically */
    gap: 1rem; /* Add space between stacked buttons */
  }

  .day-buttons button {
    margin: 0; /* No horizontal margins */
    width: 100%; /* Full width on mobile */
    max-width: 18rem; /* Prevent excessive width */
    font-size: 1rem;
  }
}
