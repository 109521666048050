.prizes-container {
  font-family: "Work Sans", sans-serif;
  background-color: #000;
  padding: 2rem;
  position: relative;
  z-index: 0;
}

.prizes-header {
  display: inline-block;
  text-align: left;
  position: relative;
  font-size: 4.5rem;
  margin: 2rem 2rem 7rem 2rem;
  font-weight: 600;
  background-image: linear-gradient(#D5E1FF, #4156F3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.prizes-list {
  display: flex;
  justify-content: center;
  gap: 2rem;
  position: relative;
  z-index: 10;
}

.prize-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease, transform 1s ease;
  position: relative; /* Ensure the items are positioned correctly */
}

.prize-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.prize-item img {
  max-width: 60%; 
  height: auto;
}

.prize-second img,
.prize-third img {
  margin-top: 3rem;
}

.prize-text {
  color: #fff;
  font-size: 1.2rem;
}

/* Keep original styles */
.additional-text {
  font-size: 1.7rem;
  margin-top: 6rem;
  margin-left: 4rem;
  background: linear-gradient(to right, #AABFFD, #4156F3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Media Queries */
@media (max-width: 768px) {
  .prizes-list {
    flex-direction: column;
    align-items: center;
  }

  .prize-first {
    order: 1;
  }

  .prize-second {
    order: 2;
  }

  .prize-third {
    order: 3;
  }

  .prizes-right {
    width: 30rem;
    height: 30rem;
    right: -8rem;
    top: -7rem;
  }
}

.prizes-left {
  background-image: url(../img/prize/prize-left.svg);
  width: 30rem;
  height: 30rem;
  left: -10rem;
  bottom: 25rem;
  z-index: 0;
}

.prizes-right {
  background-image: url(../img/prize/prize-right.svg);
  width: 60rem;
  height: 60rem;
  right: -12rem;
  bottom: 12rem;
  opacity: 0.7;
  z-index: 0;
}

.right-glow {
  background-image: url(../img/prize/right-glow.svg);
  width: 60rem;
  height: 60rem;
  right: -27rem;
  bottom: 15rem;
  opacity: 0.5;
  z-index: 0;
}

.center-glow {
  background-image: url(../img/prize/center-glow.svg);
  width: 115rem;
  height: 115rem;
  top: -35rem;
  left: -15rem;
  opacity: 0.06;
  z-index: 0;
}

.left-glow {
  background-image: url(../img/prize/left-glow.svg);
  width: 60rem;
  height: 60rem;
  left: -40rem;
  bottom: -30rem;
  opacity: 0.12;
  z-index: 0;
}

.left-dots {
  background-image: url(../img/prize/left-dots.svg);
  width: 10rem;
  height: 10rem;
  left: -8rem;
  bottom: 2rem;
  opacity: 0.5;
  z-index: 0;
}
