.tracks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:85%;
  margin:auto;
  margin-bottom: 90px;
  
}

.tracks-header {
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  background-image: linear-gradient(#d5e1ff, #4156f3);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  --s: 0.08em;
  --c: #fff;
  padding-bottom: var(--s);
  position: relative;
  z-index: 10;
  
}

.tracks-header::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: var(--s);
  background-color: var(--c);
  transition: width 0.5s ease-in-out;
}

.tracks-header:hover {
  color: transparent;
  background-image: linear-gradient(#d5e1ff, #4156f3);
  font-variation-settings: "wght" 700;
}

.tracks-header:hover::before {
  width: 100%;
}

.track-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Track card animation */
.track-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(76, 17, 245, 0.8);
  width: 95%;
  margin-bottom: 20px;
  transition: transform 0.5s ease, box-shadow 0.5s ease, filter 0.5s ease;
  opacity: 0;
  transform: translateY(20px); /* Initial transform */
  z-index: 10;
}

.track-card.visible {
  animation: fadeIn 1s ease-in-out forwards;
  opacity: 1;
  transform: translateY(0); /* Reset transform */
}

/* Hover effect */
.track-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 15px rgba(76, 17, 245, 1);
  filter: brightness(1.1);
}



.track-card-img {
  height: 100px;
  width: 100px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

.track-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.track-content {
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  margin-top:30px;
  color:white;
}


@media screen and (max-width: 576px) {
  .track-grid{
    display: block;
  }
  .track-card{
    margin:20px 20px 50px -20px;
    
  }
  
}
