.not-found {
    text-align: center;
    margin-top: 100px;
  }
  
  .not-found h1 {
    font-size: 3rem;
    color: #ff4f5a;
  }
  
  .not-found p {
    font-size: 1.5rem;
    margin: 20px 0;
  }
  
  .back-home {
    color: #fff;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
  }
  
  .back-home:hover {
    background-color: #0056b3;
  }
  